import $ from 'jquery';

$(document).ready(() => {
  let sectorIcons       = $('.sector__icon--span');
  let sectorTexts       = $('.sector__text');
  let loopInterval;

  if (sectorIcons.length) {
    changeSector();
  }

  sectorIcons.on('click', function () {
    clearTimeout(loopInterval);
    sectorIcons.removeClass('active');
    sectorTexts.removeClass('active');

    let dataId =  $(this).data('id');

    $(this).addClass('active');
    $('.sector__text[data-id="' + dataId + '"]').addClass('active');
  });

  $('.articles__container__wrapper.sector').mouseleave(function () {
    clearTimeout(loopInterval);
    changeSector();
  }).mouseenter(function () {
    clearTimeout(loopInterval);
  });

  function changeSector() {
    let nextSectorIcon    = $('.sector__icon--span.active').parents('.sector__icon').next();

    sectorIcons.removeClass('active');
    sectorTexts.removeClass('active');

    if (!nextSectorIcon.length) {
      nextSectorIcon = sectorIcons.first().parents('.sector__icon');
    }

    let dataId = nextSectorIcon.find('.sector__icon--span').data('id');

    nextSectorIcon.find('.sector__icon--span').addClass('active');
    $('.sector__text[data-id="' + dataId + '"]').addClass('active');

    loopInterval = setTimeout(changeSector, 5000);
  }
});
