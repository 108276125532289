import $ from "jquery";
window.$ = window.jQuery = $;

import "../styles/index.scss";
import "bootstrap/dist/js/bootstrap.bundle";
import "../components/sidebar";
import "./web/members";
import "./web/organisation-structures";
import "./web/projects";
import "./web/profile";
import "./web/home";
import "lightslider/dist/js/lightslider";
import "lightslider/dist/css/lightslider.css";

import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';


let bodySelector        = $('body');
let colorScheme         = $('.main-color-scheme');

$(document).ready(() => {
    bodySelector.on('click', '.article-block-redirect-button[data-toggle="true"]', function (e) {
        e.preventDefault();
        var href = $(this).attr('href');
        var pieces = href.split('/');
        // console.log(pieces)
        if (pieces[1] !== undefined && pieces[1] === 'en') {
            var title = translations.web.missing_language_modal.title_sk_version;
            var confirmButtonText = translations.web.missing_language_modal.confirm_button_sk_version;
        }
        else {
            var title = translations.web.missing_language_modal.title_en_version;
            var confirmButtonText = translations.web.missing_language_modal.confirm_button_en_version;
        }

        Swal.fire({
            showCancelButton: false,
            title: title,
            confirmButtonColor: colorScheme.css('color'),
            confirmButtonText: confirmButtonText,

        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = href;
            }
        });
    });

    bodySelector.on('click', function (e) {
       if (!$(e.target).parents('.content-dynamic-tag__tooltip').length && !$(e.target).hasClass('content-dynamic-tag')) {
           $('.content-dynamic-tag').tooltip('hide');
       }
    });

    bodySelector.on('click', '.navigation__hamburger', function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('.navigation__internal').removeClass('show');
            $('.navigation__external').removeClass('show');
            $('.navigation').removeClass('show');
            $(this).find('span').removeClass('icon-menu_open').addClass('icon-menu');
        } else {
            $(this).addClass('active');
            $('.navigation__internal').addClass('show');
            $('.navigation__external').addClass('show');
            $('.navigation').addClass('show');
            $(this).find('span').removeClass('icon-menu').addClass('icon-menu_open');
        }
    });

    $('.content-dynamic-tag').each(function () {

        if ($(this).attr('data-id') !== undefined) {
            var title = get_article_to_content_tag($(this).attr('data-id'));
        }
        else {
            var text =  $(this).data('description');

            var title = '<div class="articles__container__wrapper"><div class="row articles__container__row"> <div class="col-12"> <span class="articles__text">' + unescape(text) + '</span> </div> </div></div>';
        }

        $(this).tooltip({
            trigger: 'click focus',
            container: '.article-detail__content',
            placement: 'bottom',
            html: true,
            title: title
        });

        $(this).on('inserted.bs.tooltip', function () {
            $('.tooltip').addClass('content-dynamic-tag__tooltip');
        });
    });

    bodySelector.on('submit', '#set-language-form', function (e) {
        e.preventDefault();
        let form = $(this);
        let language = $(this).find('[name=language]').val();

        $.ajax({
            method: "POST",
            url: form.attr('action'),
            data : form.serialize(),
            success: function () {
                let oldLanguage = language;

                if (language === 'sk') {
                    oldLanguage = 'en';
                } else {
                    oldLanguage = 'sk';
                }

                window.location = window.location.href.replace('/' + oldLanguage + '/', '/' + language + '/');
            }
        });
    });

    bodySelector.on('click', '.navigation__internal__uri', function (e) {
        e.preventDefault();
        let li = $(this).parents('li');

        if (li.hasClass('active') || !li.find('.navigation__internal__dropdown').length) {
            window.location.href = $(this).attr('href');
        } else {
            $('.navigation__internal__uri').each(function () {
                $(this).parents('li').removeClass('active');
            });
            li.addClass('active');
        }
    });

    bodySelector.on('click', function (e) {
        if (!$(e.target).parents('.navigation__internal__uri ').length) {
            $('.navigation__internal__uri').each(function () {
                $(this).parents('li').removeClass('active');
            });
        }
    });

    bodySelector.on('click', '.full-content-trigger-button', function (e) {
        $(this).parents('.full-content-trigger-button-wrapper').hide();
        $('.article-detail__content').removeClass('hidden');
        $('.hidden-content').removeClass('mobile-show').hide();
    });

    bodySelector.on('click', '.news-hidden-content-shower .article-block-redirect-button', function () {
       $(this).parents('.news__container__wrapper').find('.news-hidden-content').fadeIn();
       $(this).parents('.news-hidden-content-shower').hide();
    });

    function get_article_to_content_tag(article_id) {
        var data;
        $.ajax({
            async: false,
            method: 'POST',
            data :{
                article_id : article_id
            },
            url: $('[name=content-tag-url]').val(),
            success: function (response) {
                data = response;
            }
        });

        if (data === undefined )
            return '';
        return data;
    }
});

