// import $ from 'jquery';

import $ from "jquery";

$(document).ready(() => {
    let projectsSearchFilters  = $('.profile .js-search-filter');

    projectsSearchFilters.on('click', function (e) {
        e.preventDefault();

        $(this).parents('.search-filters').find('.trapezoid').removeClass('active');
        $(this).addClass('active');

        let tag = $(this).find('span').attr('data-letters');
        let li = $(this).parents('li');

        $('.js-profile__content__item').show();

        if (!li.hasClass('js-all-filter')) {
            $('.js-profile__content__item').each(function () {
                var tagWrapper = $(this).find('.tags-wrapper');

                if ($(this).find('.tags-wrapper').length === 0) {
                    $(this).hide();
                }
                else {
                    if (tagWrapper.data('tag') !== tag.substring(1)) {
                        $(this).hide();
                    }
                }

            });
        }
    });
});
