import $ from 'jquery';

$(document).ready(() => {
  let orgStructuresItemWrapper    = $('.organisation-structures__content__column__wrapper.hover-enabled');
  let orgStructuresImageWrapper   = $('.organisation-structures__content__column__image.hover-enabled');
  let orgStructuresSearchFilters  = $('.organisation-structures .js-search-filter');

  orgStructuresImageWrapper.mouseenter(function (e) {
    e.preventDefault();

    $(this).addClass('active');
    $(this).parents('.organisation-structures__content__column')
      .find('.organisation-structures__content__column__wrapper').addClass('active')
      .find('.organisation-structures__content__wrapper__text--contact').addClass('active');
  });

  orgStructuresImageWrapper.mouseleave(function (e) {
    e.preventDefault();

    $(this).removeClass('active');
    $(this).parents('.organisation-structures__content__column')
      .find('.organisation-structures__content__column__wrapper').removeClass('active')
      .find('.organisation-structures__content__wrapper__text--contact').removeClass('active');
  });

  orgStructuresItemWrapper.mouseenter(function (e) {
    e.preventDefault();

    $(this).addClass('active');
    $(this).parents('.organisation-structures__content__column').find('.organisation-structures__content__column__image').addClass('active');
    $(this).find('.organisation-structures__content__wrapper__text--contact').addClass('active');
  });

  orgStructuresItemWrapper.mouseleave(function (e) {
    e.preventDefault();

    $(this).removeClass('active');
    $(this).parents('.organisation-structures__content__column').find('.organisation-structures__content__column__image').removeClass('active');
    $(this).find('.organisation-structures__content__wrapper__text--contact').removeClass('active');
  });

  orgStructuresSearchFilters.on('click', function (e) {
    e.preventDefault();

    $(this).parents('.search-filters').find('.trapezoid').removeClass('active');
    $(this).addClass('active');

    let type = $(this).find('span').attr('data-letters');

    $('.js-organisation-structures__content__item').show();

    if ($('.js-organisation-structures__content__item[data-type="' + type + '"]').length) {

      $('.js-organisation-structures__content__item[data-type!="' + type + '"]').hide();
    }
  });

  $('.organisation-structures__light_slider').lightSlider({
    gallery: true,
    item: 1,
    loop: false,
    slideMargin: 0,
    thumbItem: 9
  });

});
