import $ from "jquery";

$(document).ready(() => {
  let membersItemWrapper            = $('.members__content__column__wrapper');
  let membersImageWrapper           = $('.members__content__column__image');
  let membersSearchFilters          = $('.members .js-search-filter');
  let membersExpandTextSelector     = '.members__content__column__unwrap--expand .members__content__column__unwrap__text';
  let membersCollapseTextSelector   = '.members__content__column__unwrap--collapse .members__content__column__unwrap__text';

  membersImageWrapper.mouseenter(function (e) {
    e.preventDefault();

    $(this).addClass('active');
    $(this).parents('.members__content__column')
      .find('.members__content__column__wrapper').addClass('active')
      .find('.members__content__wrapper__text--description').addClass('active');
  });

  membersImageWrapper.mouseleave(function (e) {
    e.preventDefault();

    $(this).removeClass('active');
    $(this).parents('.members__content__column')
      .find('.members__content__column__wrapper').removeClass('active')
      .find('.members__content__wrapper__text--description').removeClass('active');
  });


  membersItemWrapper.mouseenter(function (e) {
    e.preventDefault();

    $(this).addClass('active');
    $(this).parents('.members__content__column').find('.members__content__column__image').addClass('active');
    $(this).find('.members__content__wrapper__text--description').addClass('active');
  });

  membersItemWrapper.mouseleave(function (e) {
    e.preventDefault();

    $(this).removeClass('active');
    $(this).parents('.members__content__column').find('.members__content__column__image').removeClass('active');
    $(this).find('.members__content__wrapper__text--description').removeClass('active');
  });

  membersSearchFilters.on('click', function (e) {
    e.preventDefault();

    $(this).parents('.search-filters').find('.trapezoid').removeClass('active');
    $(this).addClass('active');

    let type = $(this).find('span').attr('data-letters');

    $('.js-members__content__members').show();

    if ($('.js-members__content__members[data-type="' + type + '"]').length) {

      $('.js-members__content__members[data-type!="' + type + '"]').hide();
    }
  });

  $(membersExpandTextSelector).on('click', function (e) {
    e.preventDefault();

    $(this).parents('.members__content__column__unwrap--expand').removeClass('show').siblings('.members__content__column__unwrap--collapse').addClass('show');
    $(this).parents('.members__content__column__unwrap').siblings('.members__content__column__wrap').addClass('show');
  });

  $(membersCollapseTextSelector).on('click', function (e) {
    e.preventDefault();

    $(this).parents('.members__content__column__unwrap--collapse').removeClass('show').siblings('.members__content__column__unwrap--expand').addClass('show');
    $(this).parents('.members__content__column__unwrap').siblings('.members__content__column__wrap').removeClass('show');
  });
});
