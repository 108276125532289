import $ from 'jquery';

$(document).ready(() => {
  let projectsSearchFilters  = $('.projects .js-search-filter');
  let ajaxLoaderSelector = '#projects__ajax_loader';
  let loaderWrapperSelector = '.projects__content .loader__wrapper';

  $(ajaxLoaderSelector).on('click', function () {
    let page  = $(this).attr('data-page');
    let url   = $(this).attr('data-url');
    let tag   = $('.projects .js-search-filter.active').find('span').attr('data-letters');

    $(loaderWrapperSelector).addClass('show');

    $.ajax({
        url: url,
        data : {
          page : page,
          tag : tag,
          per_page : /Mobi/.test(navigator.userAgent) ? 4 : 12
        },
        success: function(response) {
          $(ajaxLoaderSelector).attr('data-page', parseInt(page) + 1);
          $('.projects__content .projects__content__item__wrapper').append(response);
          $(loaderWrapperSelector).removeClass('show');

          if ($('[name=hide_ajax_loader]').length) {
            $(ajaxLoaderSelector).addClass('hidden');
          }
        }
    });
  });

  projectsSearchFilters.on('click', function (e) {
    e.preventDefault();
    $(ajaxLoaderSelector).attr('data-page', 0);

    $(this).parents('.search-filters').find('.trapezoid').removeClass('active');
    $(this).addClass('active');
    $(ajaxLoaderSelector).removeClass('hidden');

    let tag = $(this).find('span').attr('data-letters');
    let page = $(ajaxLoaderSelector).attr('data-page');

    $(loaderWrapperSelector).addClass('show');

    $.ajax({
        url: $(ajaxLoaderSelector).attr('data-url'),
        data : {
          page : page,
          tag : tag,
          per_page : /Mobi/.test(navigator.userAgent) ? 4 : 12
        },
        success: function(response) {
          $(ajaxLoaderSelector).attr('data-page', parseInt(page) + 1);
          $('.projects__content .projects__content__item__wrapper').html('').append(response);
          $(loaderWrapperSelector).removeClass('show');

          if ($('[name=hide_ajax_loader]').length) {
            $(ajaxLoaderSelector).addClass('hidden');
          }
        }
    });
  });

  $('.projects .js-search-filter span[data-letters="#všetky"], .projects .js-search-filter span[data-letters="#all"]').trigger('click');
});
